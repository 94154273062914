import axios from "axios";

const meuValor = ""

const apis = axios.create({
  baseURL: "https://api.fintail.com.br/api",
});


export default apis
