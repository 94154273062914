import { createRouter, createWebHistory, NavigationGuard, RouteRecordRaw } from "vue-router";

import Dashboard from "../views/Dashboard.vue";
import Produtos from "../views/Produtos.vue";
import Tables from "../views/Tables.vue";
import Billing from "../views/Billing.vue";
import VirtualReality from "../views/VirtualReality.vue";
import RTL from "../views/Rtl.vue";
import Profile from "../views/Profile.vue";
import Signup from "../views/Signup.vue";
import Signin from "../views/Signin.vue";

import store from '../store'

const privateRoute: NavigationGuard = function(to, from, next) {

  if (!store.state.user.isAuthenticated) {
    next({ name: 'Signin' });
  } else {
    next();
  }
};

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "/",
    redirect: "/dashboard",
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    beforeEnter: privateRoute
  },
  {
    path: "/produtos",
    name: "Produtos",
    component: Produtos,
    beforeEnter: privateRoute
  },
  {
    path: "/tables",
    name: "Tables",
    component: Tables,
    beforeEnter: privateRoute
  },
  {
    path: "/billing",
    name: "Billing",
    component: Billing,
    beforeEnter: privateRoute
  },
  {
    path: "/virtual-reality",
    name: "Virtual Reality",
    component: VirtualReality,
    beforeEnter: privateRoute
  },
  {
    path: "/rtl-page",
    name: "RTL",
    component: RTL,
    beforeEnter: privateRoute
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    beforeEnter: privateRoute
  },
  {
    path: "/signin",
    name: "Signin",
    component: Signin,
  },
  {
    path: "/signup",
    name: "Signup",
    component: Signup,
  },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

export default router;
