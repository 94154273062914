
import Navbar from "@/examples/PageLayout/Navbar.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import ArgonButton from "@/components/ArgonButton.vue";
const body = document.getElementsByTagName("body")[0];

import { defineComponent, ref } from 'vue';
import {useStore} from "vuex";

import fintailapi from "../api";

export default defineComponent({
  name: "signin",
  components: {
    Navbar,
    ArgonInput,
    ArgonSwitch,
    ArgonButton,
  },
  data() {
    return {
      username: "",
      password: "",
      isLast: false,
      store : useStore()
    }
  },
  created() {
    this.store.state.hideConfigButton = true;
    this.store.state.showNavbar = false;
    this.store.state.showSidenav = false;
    this.store.state.showFooter = false;
    body.classList.remove("bg-gray-100");


  },
  beforeUnmount() {
    this.store.state.hideConfigButton = false;
    this.store.state.showNavbar = true;
    this.store.state.showSidenav = true;
    this.store.state.showFooter = true;
    body.classList.add("bg-gray-100");
  },
   methods: {
    doLogin() {

        fintailapi
        .post('/backend/login', { "email": this.username, "password": this.password  })
        .then((response:any) => {

          this.store.state.user.isAuthenticated = true;

          fintailapi.defaults.headers.common['Authorization'] = response.data.token;

          this.$router.push('/dashboard');

        })
        .catch((error:any) => {

          //this.isOpenRef = true;

        })
      //this.store.state.user.isAuthenticated = true;
       //this.$router.push('/dashboard');
    },
    doAuth() {

    }
   }
});
