import { createApp } from "vue"
import App from './App.vue'
import store from "./store"
import router from "./router"
import "./assets/css/nucleo-icons.css"
import "./assets/css/nucleo-svg.css"
import ArgonDashboard from "./argon-dashboard"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

const app = createApp(App)
.component("FontAwesomeIcon", FontAwesomeIcon)
.use(store)
.use(router)
.use(ArgonDashboard);

router.isReady().then(() => {
  app.mount('#app');
});

