
import { defineComponent, ref } from 'vue';

import ProdutosTable from "./components/ProdutosTable.vue";
import ProjectsTable from "./components/ProjectsTable.vue";

import fintailapi from "../api";

export default defineComponent({
  name: "tables",
  components: {
    ProdutosTable,
    ProjectsTable
  },
  data() {
    return {
      stats: {
        titleColor: "opacity-7 text-white",
        descColor: "text-white",
        trip: {
          title: "Today's Trip",
          desc: "145 KM",
          classIcon: "text-dark ni ni-money-coins",
        },
        health: {
          title: "Battery Health",
          desc: "99 %",
          classIcon: "text-dark ni ni-controller ",
        },
        speed: {
          title: "Average Speed",
          desc: "56 Km/h",
          classIcon: "text-dark ni ni-delivery-fast",
        },
        volume: {
          title: "Music Volume",
          desc: "15/100",
          classIcon: "text-dark ni ni-note-03",
        },
      },
      produtos: [],
      current_page:0,
      total_pages:0,
    };
  },
  methods: {
    getProdutos(offset:number) {

        fintailapi
        .post('/backend/produtos', { offset : offset, limit : 10})
        .then((response:any) => {

          this.current_page = offset;

          this.produtos = response.data.rows
          this.total_pages = response.data.count / 10

        })
        .catch((error:any) => {

          //this.isOpenRef = true;

        })

      //this.store.state.user.isAuthenticated = true;
       //this.$router.push('/dashboard');
    },
   },
   created() {

      this.getProdutos(0)


    },

});


