
export default {
  name: "produtos-table",
  components: {

  },
    props: {
      produtos: {
        type: Array,
        default: () => {
          [];
        },
      },
    },

};
